import { Scalars, Maybe, DeliveryType } from '@types';

export enum AdressType {
  DROP = 'DROP',
  PICKUP = 'PICKUP'
}

export enum ShipmentType {
  COD = 'COD',
  PREPAID = 'PREPAID'
}

export enum SyncStatusConfig {
  API = 'API',
  WEBHOOK = 'WEBHOOK'
}

export type ShipmentAddress = {
  address: Scalars['String'];
  city: Scalars['String'];
  contactEmail: Scalars['String'];
  contactName: Scalars['String'];
  countryCode: Scalars['String'];
  createdAt: Scalars['Date'];
  deletedAt: Scalars['Int'];
  district: Scalars['String'];
  entityId: Scalars['ID'];
  latitude: Scalars['Float'];
  locationPhoneCode: Scalars['String'];
  longitude: Scalars['Float'];
  phoneNumber: Scalars['String'];
  postalCode: Scalars['String'];
  // "shipment" with empty object "{}"
  state: Scalars['String'];
  type: AdressType;
  updatedAt: Scalars['Date'];
};

export type ShipmentItem = {
  breadth: Maybe<Scalars['Int']>;
  createdAt: Scalars['Date'];
  deletedAt: Scalars['Int'];
  description: Scalars['String'];
  entityId: Scalars['ID'];
  height: Maybe<Scalars['Int']>;
  imageUrl: Maybe<Scalars['String']>;
  length: Maybe<Scalars['Int']>;
  price: Scalars['Float'];
  quantity: Scalars['Int'];
  returnDays: Scalars['Int'];
  // "shipment" with empty object "{}"
  sku: Scalars['String'];
  updatedAt: Scalars['Date'];
  weight: Maybe<Scalars['Int']>;
};

export type Order = {
  addressInfo: ShipmentAddress[];
  awb: Scalars['String'];
  breadth: Scalars['Int'];
  codAmount: Scalars['Float'];
  createdAt: Scalars['Date'];
  currencyCode: Scalars['String'];
  customerPhoneNumber: Scalars['String'];
  deletedAt: Scalars['Int'];
  deliveredAt: Maybe<Scalars['String']>;
  deliveryType: DeliveryType;
  entityId: Scalars['ID'];
  height: Scalars['Int'];
  invoiceValue: Scalars['Float'];
  lastSyncedAt: Maybe<Scalars['String']>;
  length: Scalars['Int'];
  orderDate: Scalars['String'];
  orderId: Scalars['String'];
  partnerAccountCode: Scalars['String'];
  partnerAccountId: Scalars['ID'];
  partnerCode: Scalars['String'];
  pickedUpAt: Maybe<Scalars['String']>;
  referenceNumber: Scalars['String'];
  rvpReason: Maybe<Scalars['String']>;
  shipDate: Maybe<Scalars['String']>;
  shipmentItems: ShipmentItem[];
  shipmentType: ShipmentType;
  status: Scalars['String'];
  storeId: Scalars['String'];
  syncStatusConfig: Maybe<SyncStatusConfig>;
  updatedAt: Scalars['Date'];
  weight: Scalars['Int'];
  paymentMode: Scalars['String'];
};

export type OrderResponse = {
  count: Scalars['Int'];
  getAllShipmentsResponses: Order[];
};

export type CustomFields = {
  [key: string]: string[];
};

export enum ShipmentStatusUpdateType {
  WEBHOOK = 'WEBHOOK',
  API = 'API',
  MANUAL_STATUS = 'MANUAL_STATUS'
}

export enum GenerateReportQueryParams {
  courierPartnerIds = 'courierPartnerIds',
  createdDateFrom = 'createdDateFrom',
  createdDateTo = 'createdDateTo',
  deliverDateFrom = 'deliverDateFrom',
  deliverDateTo = 'deliverDateTo',
  orderIds = 'orderIds',
  pickupDateFrom = 'pickupDateFrom',
  pickupDateTo = 'pickupDateTo',
  referenceNos = 'referenceNos',
  statuses = 'statuses',
  awbs = 'awbs'
}

export type Product = {
  entityId: Scalars['ID'];
  sku: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  quantity: Scalars['String'];
  price: Scalars['String'];
};

export type ShipmentTimeLine = {
  status: Scalars['String'];
  remarks: Scalars['String'];
  statusTime: Scalars['String'];
  entityId: Scalars['ID'];
  shipmentStatusUpdateType: ShipmentStatusUpdateType;
  courierPartnerStatus: Scalars['String'];
};
