/* eslint-disable no-param-reassign */
/* eslint-disable prefer-destructuring */
import { CustomDateTypes, DateType } from '@types';
import Button from '@components/button/Button';
import { getEndOfDay, getStartOfDay } from '@utils/date';
import Calendar from '../../calendar/Calendar';

const SelectorCalender = ({
  fromDate,
  toDate,
  handleFilterSelection,
  filterState,
  name
}: {
  fromDate: DateType;
  toDate: DateType;
  handleFilterSelection: (filter: any) => void;
  filterState: any;
  name: any;
}) => {
  const handleChange = () =>
    handleFilterSelection({
      ...filterState,
      [name]: {
        ...filterState[name],
        fromDate,
        isDateSelected: true,
        toDate
      }
    });

  const isActiveFilter = (startTime: string | Date, endTime: string | Date) =>
    new Date(startTime).getTime() === new Date(fromDate.value).getTime() &&
    getStartOfDay(new Date(endTime)).getTime() ===
      new Date(toDate.value).getTime();

  return (
    <div className="flex">
      {filterState[name]?.customDateOptions && (
        <div className="m-8 flex flex-col gap-2">
          {filterState[name]?.customDateOptions.map((item: CustomDateTypes) => (
            <Button
              className={`min-w-max rounded-lg !text-sm !font-normal hover:bg-primary10 ${
                isActiveFilter(item.startDate, item.toDate) && '!bg-primary10'
              }`}
              handleClick={() => {
                fromDate.value = item.startDate || getStartOfDay(new Date());
                toDate.value = getStartOfDay(
                  new Date(item.toDate) || getEndOfDay(new Date())
                );
                handleChange();
              }}
              variant="transparent"
              disabled={!item.startDate}
              key={item.key}
            >
              {item.displayName}
            </Button>
          ))}
        </div>
      )}
      <Calendar
        handleChange={(dateArray) => {
          [fromDate.value, toDate.value] = dateArray;
          handleChange();
        }}
        dateRange={[new Date(fromDate.value), new Date(toDate.value)]}
      />
    </div>
  );
};

export default SelectorCalender;
