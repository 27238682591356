import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useNotify from '@hooks/notify';
import { AUTH } from '@constants/routes';
import {
  baseApi,
  authApi,
  baseApiExcludedRegion,
  baseWebhookApi,
  baseWebhookApiExcludedRegion,
  base3plApi,
  base3plApiExcludedRegion
} from '@core/baseApi';
import { useLogoutMutation } from '@features/authentication/queries/AuthQuery';

const useLogout = () => {
  const dispatch = useDispatch();
  const [logout] = useLogoutMutation();
  const { createErrorAlert } = useNotify();
  const navigate = useNavigate();
  const handleLogout = async () => {
    const res = await logout('');
    if ('error' in res) createErrorAlert(res?.error?.data?.message);
    else {
      dispatch(baseApi.util.resetApiState());
      dispatch(authApi.util.resetApiState());
      dispatch(baseApiExcludedRegion.util.resetApiState());
      dispatch(baseWebhookApi.util.resetApiState());
      dispatch(baseWebhookApiExcludedRegion.util.resetApiState());
      dispatch(base3plApi.util.resetApiState());
      dispatch(base3plApiExcludedRegion.util.resetApiState());
      navigate(AUTH.LOGIN);
    }
  };
  return { handleLogout };
};

export default useLogout;
