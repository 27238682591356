import { Scalars } from '@types';

export const API_URL = process.env.REACT_APP_API_URL;
export const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;
export const WEBHOOK_URL = process.env.REACT_APP_WEBHOOK_URL;
export const THREEPL_URL = process.env.REACT_APP_3PL_URL;
export const ORDER_NON_STATUSES =
  process.env.REACT_APP_ORDER_NON_STATUSES?.split(',');
export const END_POINTS = {
  AUTH: {
    LOGIN: '/auth/login',
    LOGOUT: '/auth/logout',
    VALIDATE_TOKEN: '/auth/is-valid-token'
  },
  USER: {
    SET_PASSWORD: '/users/register',
    INVITE: '/users/invite',
    INITIATE_RESET_PASSWORD: '/users/initiate-reset-password',
    RESET_PASSWORD: '/users/reset-password',
    RESEND_INVITE: (entityId: string) => `/users/${entityId}/re-invite`,
    GET_USERS: '/users',
    GET_USER: '/users',
    GET_CURRENT_USER: '/users/me',
    DELETE_USER: '/users',
    UPDATE_STATUS: '/users',
    UPDATE_USER: '/users',
    UPDATE_SELF_DETAILS: '/users/me'
  },
  ORDER: {
    GET_ORDERS: '/shipment/fetch',
    GET_ORDER: (entityId: Scalars['ID']) => `/shipment/${entityId}`,
    GENERATE_REPORT: '/shipment/generate-report',
    GENERATE_REPORT_FIELDS: '/shipment/generate-report-fields',
    UPDATE_ORDER_STATUS: '/shipment/status/bulk',
    GET_ALL_STATUSES: '/shipment/status',
    GET_ALL_MANUAL_STATUSES: '/shipment/manual-status',
    GET_SHIPMENT_LABEL: '/shipment/label',
    GET_ALL_SHIPMENT_LABELS: '/shipment/labels',
    GET_TIMELINE_REPORT: '/shipment/timeline/download'
  },
  RULES: {
    CREATE_RULE: '/rules',
    GET_RULES: '/rules',
    GET_RULE: '/rules',
    UPDATE_RULE: '/rules',
    DELETE_RULE: '/rules',
    UPDATE_STATUS: '/rules',
    UPDATE_PRIORITY: '/rules/update_priority'
  },
  COURIER: {
    GET_COURIER_PARTNERS: '/partners',
    GET_COURIER_PARTNER_BY_ID: (entityId: string) => `/partners/${entityId}`,
    GET_COURIER_PARTNERS_ACCOUNTS: '/partner_accounts',
    GET_COURIER_PARTNERS_ACCOUNTS_FILTER: '/partner_accounts/filter',
    UPDATE_PARTNER_ACCOUNT_STATUS: '/partner_accounts',
    DELETE_PARTNER_ACCOUNT: (entityId: string) =>
      `/partner_accounts/${entityId}`,
    UPDATE_PARTNER_CREDENTIALS: (entityId: string) =>
      `/partner-account/${entityId}/credentials`,
    GET_COURIER_PARTNERS_ACCOUNT_CREDENTIALS_BY_ID: (entityId: string) =>
      `/partner-account/${entityId}/credentials`,
    UPDATE_PARTNER_ACCOUNT: (entityId: string) =>
      `/partner_accounts/${entityId}`,
    GET_COURIER_PARTNERS_ACCOUNT_BY_ID: (entityId: string) =>
      `/partner_accounts/${entityId}`,
    CREATE_COURIER_PARTNER_ACCOUNT: '/partner_accounts',
    GET_SERVICEABLE_PARTNERS: '/partner_service_areas/partners',
    GET_PARTNER_ACCOUNT_TIME_SLOTS: (entityId: string) =>
      `/partner-account-time-slot/${entityId}`,
    CREATE_PARTNER_ACCOUNT_TIME_SLOTS: (entityId: string) =>
      `/partner-account-time-slot/${entityId}`,

    UPDATE_PARTNER_ACCOUNT_TIME_SLOT: '/partner-account-time-slot',
    UPDATE_PARTNER_ACCOUNT_TIME_SLOT_STATUS: '/partner-account-time-slot',

    DELETE_PARTNER_ACCOUNT_TIME_SLOT: '/partner-account-time-slot',

    GET_COURIER_PARTNER_STATUS_MAPPING: (entityId: string) =>
      `/partner-status-map/${entityId}`,
    UPDATE_COURIER_PARTNER_STATUS_MAPPING: (entityId: string) =>
      `/partner-status-map/${entityId}`
  },
  ALLOCATION: {
    GET_SEARCH_HISTORY: '/event_logs',
    UPDATE_TEST_RECOMMENDATION: '/recommendation_api'
  },
  ADDRESS_MAPPER: {
    GET_ADDRESS_MAPPER: '/partners-mapper-info',
    GET_ADDRESS_MAPPER_DOWNLOAD_LINK: `/address-mapper/download`
  },
  FIELDS: {
    GET_FIELDS: '/fields',
    GET_FIELDS_RULE: '/fields/rule',
    GET_FIELD_TYPES: '/field_types',
    CREATE_CUSTOM_FIELD: '/fields/custom-field'
  },
  FILE_UPLOAD: {
    GET_FILE_UPLOADS: '/file-upload',
    GET_FILE_UPLOAD: '/file-upload',
    GET_FILE_UPLOAD_PREVIEW: '/file-upload/preview-url',
    GET_FILE_UPLOAD_SAMPLE: '/file-upload/sample-file-url',
    POST_FILE_DETAILS: '/file-upload'
  },
  ROLES: {
    GET_ENTITIES: '/entities',
    GET_ROLES: '/roles',
    GET_ROLES_FILTER: '/roles/filters',
    GET_ROLE: (entityId: Scalars['ID']) => `/roles/${entityId}`,
    UPDATE_ROLE_BY_ID: (entityId: Scalars['ID']) => `/roles/${entityId}`,
    CREATE_ROLE: '/roles',
    DELETE_ROLE: (entityId: Scalars['ID']) => `/roles/${entityId}`
  },
  REGIONS: {
    GET_ALL_REGIONS: '/regions',
    GET_ALL_REGIONS_FILTER: '/regions/filters',
    CREATE_REGION: '/regions',
    UPDATE_REGION: (entityId: string) => `/regions/${entityId}`,
    GET_ALL_TIMEZONES: '/timezones'
  },
  SERVICE_AREA: {
    DOWNLOAD_AREAS: '/partner_service_areas/download'
  },
  WEBHOOKS: {
    GET_WEBHOOKS_LIST: '/webhooks',
    GET_WEBHOOK_BY_ID: (entityId: Scalars['ID']) => `/webhooks/${entityId}`,
    CREATE_WEBHOOKS: '/webhooks',
    GET_AUTHENTICATION_LIST: '/webhooks/authentications',
    UPDATE_WEBHOOK: '/webhooks',
    UPDATE_WEBHOOK_STATUS: '/webhooks',
    DELETE_WEBHOOK: '/webhooks',
    RETRIGGER: '/shipment/webhook-retrigger'
  },
  WAREHOUSES: {
    GET_WAREHOUSES: '/warehouse',
    GET_WAREHOUSE: (entityId: Scalars['ID']) => `/warehouse/${entityId}`,
    CREATE_WAREHOUSE: '/warehouse',
    UPDATE_STATUS: '/warehouse',
    UPDATE_WAREHOUSE: '/warehouse',
    DELETE_WAREHOUSE: (entityId: Scalars['ID']) => `/warehouse/${entityId}`
  },
  GETLOG: {
    GET_INTEGRATION_APILOGS: '/event_logs',
    GET_WEBHOOK_LOGS: '/webhooks/execution-history'
  },
  DASHBOARD: {
    GET_ORDERS_COUNT_BY_CITY: '/analytics/orders/city',
    GET_ORDERS_COUNT_BY_DATE: '/analytics/orders/date',
    GET_ORDERS_COUNT_BY_PARTNER: '/analytics/orders/partner',
    GET_ORDER_HANDOVER_COUNT: '/analytics/orders/pending',
    GET_ORDERS_COUNT_BY_STATUS: '/analytics/orders/status',
    GET_OVERVIEW_COUNT: '/analytics/overview',
    GET_TURNAROUND_TIME_BY_PARTNER: '/analytics/partner/turnaround',
    GET_UTILIZATION_BY_PARTNER_ACCOUNT: '/analytics/partner/utilization',
    GET_RULES_BY_PARTNER_ACCOUNT: '/analytics/partner/rules',
    DOWNLOAD_ORDER_REPORT_BY_CITY: '/analytics/downloads/orders/city',
    DOWNLOAD_ORDER_REPORT_BY_DATE: '/analytics/downloads/orders/date',
    DOWNLOAD_ORDER_REPORT_BY_PARTNER: '/analytics/downloads/orders/partner',
    DOWNLOAD_ORDER_REPORT_BY_PENDING: '/analytics/downloads/orders/pending',
    DOWNLOAD_ORDER_REPORT_BY_STATUS: '/analytics/downloads/orders/status'
  }
};

export const tags = ['getWebhooksById'];
