export const AUTH = {
  LOGIN: '/login',
  SET_PASSWORD: '/register',
  RESET_PASSWORD: '/reset',
  CONFIRM_RESET: '/reset/confirm'
};

export const USER = {
  USER_MANAGEMENT: '/users'
};

export const REGION = {
  REGION_MANAGEMENT: '/regions'
};

export const ROLE = {
  ROLE_MANAGEMENT: '/roles',
  VIEW_ROLE: '/roles/:roleId',
  EDIT_ROLE: '/roles/edit/:roleId',
  CREATE_ROLE: '/roles/create'
};

export const ORDERS = {
  ORDER_MANAGEMENT: '/order-management',
  ORDERS: '/order-management/orders',
  ORDER_DETAILS: '/order-management/orders/:orderId',
  GENERATE_REPORT: '/order-management/orders/generate-report',
  BULK_ORDER_UPLOAD: '/order-management/bulk-order-upload',
  MANUAL_STATUS_UPDATE: '/order-management/manual-status-update'
};

export const ALLOCATION = {
  ALLOCATION: '/allocation',
  RULE: '/allocation/rule',
  CREATE_RULE: '/allocation/rule/create-rule',
  EDIT_RULE: '/allocation/rule/edit-rule/:ruleId'
};

export const COURIER = {
  COURIER: '/courier',
  PARTNERS: '/courier/partners',
  PARTNER_ACCOUNT: '/courier/partners/:partnerId',
  CREATE_COURIER_ACCOUNT: '/courier/partners/:partnerId/add-courier-account',
  PARTNER_ACCOUNT_DETAIL: '/courier/partners/:partnerId/account/:accountId',
  PARTNER_ACCOUNT_CREDENTIALS:
    '/courier/partners/:partnerId/account/:accountId/credentials',
  PARTNER_ACCOUNT_SERVICEABILITY:
    '/courier/partners/:partnerId/account/:accountId/serviceability',
  ALLOCATION: '/courier/allocation',
  ADDRESS_MAPPER: '/courier/address-mapper',
  ADDRESS_MAPPER_UPLOAD: '/courier/address-mapper/:id/upload',
  UPLOAD: '/upload',
  CHECK_SERVICEABILITY: '/courier/check-serviceability',
  RECOMMENDATION: '/courier/recommendation',
  STATUS_MAPPING: '/courier/status-mapping'
};

export const WEBHOOK = {
  WEBHOOK: '/webhook',
  ADD_WEBHOOK: '/webhook/add-webhook',
  EDIT_WEBHOOK: '/webhook/edit/:webhookId'
};

export const MY_ACCOUNT = '/myAccount';

export const HOME = '/';

export const DASHBOARD = '/dashboard';

export const WAREHOUSE = {
  WAREHOUSE_MANAGEMENT: '/order-management/warehouse',
  CREATE_WAREHOUSE: '/order-management/warehouse/create',
  EDIT_WAREHOUSE: '/order-management/warehouse/edit/:warehouseId'
};

export const GETLOG = {
  GETLOG_MANAGEMENT: '/getLog'
};

export const ACCESS_DENIED = '/accessDenied';
