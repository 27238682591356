import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

import { ORDERS } from '@constants/routes';

// Orders management
const Orders = lazy(() => import('@features/orders/pages/orders/Orders'));
const OrderDetails = lazy(
  () => import('@features/orders/pages/order-details/OrderDetails')
);
const BulkOrderUpload = lazy(
  () => import('@features/orders/pages/bulk-order-upload/BulkOrderUpload')
);
const GenerateReport = lazy(
  () => import('@features/orders/pages/generate-report/GenerateReport')
);
const ManualStatusUpdate = lazy(
  () => import('@features/orders/pages/manual-status-update/ManualStatusUpdate')
);

export const OrderRoutes: RouteObject = {
  path: ORDERS.ORDER_MANAGEMENT,
  children: [
    {
      path: ORDERS.ORDERS,
      element: <Orders />
    },
    {
      path: ORDERS.GENERATE_REPORT,
      element: <GenerateReport />
    },
    {
      path: ORDERS.ORDER_DETAILS,
      element: <OrderDetails />
    },
    {
      path: ORDERS.BULK_ORDER_UPLOAD,
      element: <BulkOrderUpload />
    },
    {
      path: ORDERS.MANUAL_STATUS_UPDATE,
      element: <ManualStatusUpdate />
    }
  ]
};

export default OrderRoutes;
