import { Scalars } from '@types';

/**
 **********
 * Generic utility functions
 */

/**
 * No operation function
 */
const noop = () => {};

export const getRandomId = (): Scalars['ID'] =>
  (Math.random() + 1).toString(36).substring(7);

// eslint-disable-next-line import/prefer-default-export
export { noop };

export const validateWithRegexp = (regex: any, value?: string) =>
  regex.test(value);

// https://stackoverflow.com/questions/43118692/typescript-filter-out-nulls-from-an-array
// eslint-disable-next-line import/prefer-default-export, @typescript-eslint/no-explicit-any
export const notEmpty = <TValue extends any>(
  value: TValue | null | undefined
): value is TValue => {
  if (value === null || value === undefined) return false;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const testDummy: TValue = value; // dummy compiler check
  return true;
};

export const timeFormat = (value: any) =>
  value.toLocaleString('en-US', { minimumIntegerDigits: 2 });

export const downloadFileFromUrl = (
  url: string,
  fileName: string,
  openInNewTab?: boolean
) => {
  const element = document.createElement('a');
  element.setAttribute('href', url);
  element.setAttribute('download', fileName);
  element.style.display = 'none';
  if (openInNewTab) {
    element.target = '_blank';
  }
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};

export const getFileContentTypes = (fileType: string): string => {
  let contentType = '';
  switch (fileType) {
    case 'csv': {
      contentType = 'text/csv';
      break;
    }
    default:
      break;
  }
  return contentType;
};

export const formatBytes = (
  bytes: Scalars['Float'],
  decimals = 2
): Scalars['String'] => {
  if (typeof bytes === 'number' && typeof decimals === 'number' && bytes > 0) {
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
  }
  return '0 bytes';
};

export const readFileAsBase64 = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = async (event: any) => {
      if (event?.target?.result) resolve(event.target.result);
    };
    reader.onerror = (event: ProgressEvent) => {
      if (event.type) reject(event);
    };
    // Read file as base64
    // reader.readAsDataURL(file);
    //  Read file as binary
    reader.readAsText(file, 'UTF-8');
  });

export const trimEmptyValue = (value: string) =>
  value.trim() === '' ? '' : value;

export const exportCsvFromData = (data: any, fileName: string) => {
  const blob = new Blob([data], { type: 'text/csv;charset=utf-8;' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = fileName;
  a.click();
  window.URL.revokeObjectURL(url);
};
