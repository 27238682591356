/* eslint-disable import/prefer-default-export */
import { Role, CourierDeliveryType } from '@types';
import {
  ALLOCATION,
  COURIER,
  GETLOG,
  ORDERS,
  REGION,
  ROLE,
  USER,
  WAREHOUSE,
  WEBHOOK
} from './routes';

export const roles = [
  {
    entityId: '61dc113c-5904-4f4b-85fb-63585c3bd7f9',
    name: 'Admin'
  }
] as Role[];

export const DeliveryTypes = [
  {
    key: 'FORWARD',
    value: 'Forward'
  },
  {
    key: 'REVERSE',
    value: 'Reverse'
  },
  {
    key: 'B2B',
    value: 'B2B'
  }
] as CourierDeliveryType[];

export const courierPartner = [
  {
    entityId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
    name: 'Courier Partner 1'
  },
  {
    entityId: '3fa85f64-5717-4562-b3fc-dfdsfsfds',
    name: 'Courier Partner 2'
  },
  {
    entityId: '3fa85f64-5717-4562-b3fc-wqwerwte',
    name: 'Courier Partner 3'
  }
];

export const GlobalPages = [
  USER.USER_MANAGEMENT,
  REGION.REGION_MANAGEMENT,
  ROLE.ROLE_MANAGEMENT,
  ROLE.VIEW_ROLE,
  ROLE.EDIT_ROLE,
  ROLE.CREATE_ROLE,
  WEBHOOK.WEBHOOK,
  WEBHOOK.ADD_WEBHOOK,
  WEBHOOK.EDIT_WEBHOOK,
  COURIER.ADDRESS_MAPPER,
  COURIER.ADDRESS_MAPPER_UPLOAD,
  COURIER.STATUS_MAPPING,
  GETLOG.GETLOG_MANAGEMENT
];

export const parentRoutesWithChilds = [
  {
    parentPath: ROLE.ROLE_MANAGEMENT,
    childPaths: [ROLE.CREATE_ROLE, ROLE.EDIT_ROLE, ROLE.VIEW_ROLE]
  },
  {
    parentPath: ORDERS.ORDERS,
    childPaths: [
      ORDERS.BULK_ORDER_UPLOAD,
      ORDERS.GENERATE_REPORT,
      ORDERS.MANUAL_STATUS_UPDATE
    ]
  },
  {
    parentPath: ALLOCATION.RULE,
    childPaths: [
      ALLOCATION.CREATE_RULE,
      ALLOCATION.EDIT_RULE,
      ALLOCATION.RULE,
      COURIER.RECOMMENDATION
    ]
  },
  {
    parentPath: COURIER.PARTNERS,
    childPaths: [
      COURIER.PARTNER_ACCOUNT,
      COURIER.PARTNER_ACCOUNT_DETAIL,
      COURIER.PARTNER_ACCOUNT_SERVICEABILITY,
      COURIER.PARTNER_ACCOUNT_CREDENTIALS,
      COURIER.CREATE_COURIER_ACCOUNT,
      COURIER.ALLOCATION,
      COURIER.ADDRESS_MAPPER,
      COURIER.CHECK_SERVICEABILITY
    ]
  },
  {
    parentPath: WEBHOOK.WEBHOOK,
    childPaths: [WEBHOOK.ADD_WEBHOOK, WEBHOOK.EDIT_WEBHOOK]
  },
  {
    parentPath: WAREHOUSE.WAREHOUSE_MANAGEMENT,
    childPaths: [WAREHOUSE.CREATE_WAREHOUSE, WAREHOUSE.EDIT_WAREHOUSE]
  }
];
